import React from "react";
import {Trans} from "@lingui/macro";
import "./Paging.css";

const Paging = ({prevLink, nextLink, label}) => {
    return (prevLink || nextLink) && (
        <div className="Paging">
            {prevLink && (<a href={prevLink} title="Predchádzajúce"><Trans>Predchádzajúce</Trans></a>)}
            {label && (<span>{label}</span>)}
            {nextLink && (<a href={nextLink} title="Ďalej"><Trans>Ďalej</Trans></a>)}
        </div>
    );
};

export default Paging;