import React from "react";

const ItemListCol = ({ items, columnNumber, columnClassName, className }) => {
  const renderItemListCol = () => {
    if (items.length <= 0) return "";

    let numOfRows = Math.ceil(items.length / columnNumber);
    let result = [];
    for (let i = 1; i <= numOfRows; i++) {
      let rowItems = items.slice((i - 1) * columnNumber, i * columnNumber);
      result.push(
        <div className="row" key={i}>
          {rowItems.map((rowItem, key) => {
            return (
              <div className={columnClassName} key={key}>
                {rowItem}
              </div>
            );
          })}
        </div>
      );
    }
    return <div className={"ItemListCol " + className}>{result}</div>;
  };

  return renderItemListCol();
};

export default ItemListCol;
