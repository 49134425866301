import React from "react";
import {Trans} from "@lingui/macro";
import ItemListCol from "./ItemListCol";
import VideoItem from "./VideoItem/VideoItem"
import IfNotEmpty from "./IfNotEmpty";
import "./VideoList.css"

const Videos = ({items, title}) => {
    return (
            <div className="SideBarBox VideoList">
                <h2><Trans>{title}</Trans></h2>
                <IfNotEmpty items={items}>
                    <ItemListCol columnNumber={4} columnClassName="col-md-6 col-lg-3"
                                 items={items.map((node) => <VideoItem item={node}/>)}/>
                </IfNotEmpty>
            </div>
    );
};

export default Videos;