import React from "react";
import { Link } from "gatsby";
import "styles/global.css";
import { Trans } from "@lingui/macro";
import "./VideoItem.css"
import IfNotEmpty from "./../IfNotEmpty";

const VideoItem = ({ item }) => {
    var  d = new Date(item.publication_date);
    const duration = Math.floor(item.json.duration / 60) + ':' + (item.json.duration % 60);

  return (
    <div className="video-item">
        <div className="preview">
            {item && item.json && item.json.pictures && item.json.pictures.sizes[0] ? <Link to={"/sk/video/"+item.id}><img src={item.json.pictures.sizes[0].link} alt={item.title} /></Link>: ""}

            <span className="length">{duration}</span>
        </div>
        <h6>{item.title}</h6>
            {item && item.json && item.json.stats && item.json.stats.plays ? <p><Trans>Dátum</Trans>: {d.toLocaleDateString('sk')} &nbsp;&nbsp;&nbsp;<Trans>Počet videní</Trans>: {item.json.stats.plays}</p>: ""}
    </div>
  );
};

export default VideoItem;
