import React, {useEffect, useState} from "react";
import {withI18n} from "@lingui/react";
import {getLocaleFromPath} from "../../utils/i18n.js";
import Layout from "components/layout/layout";
import Videos from "../../components/Videos"
import {graphql, useStaticQuery} from "gatsby";
import Paging from "../../components/Paging";

const IndexPage = ({location}) => {
    const query = useStaticQuery(graphql`
        query {
          site {
            siteMetadata {
              videoRest {
                endpoints {
                  taggedVideos
                }
              }
            }
          }
        }
    `);

    const [videos, setVideos] = useState([]);
    const [title, setTitle] = useState('');
    const [prevLink, setPrevLink] = useState(null);
    const [nextLink, setNextLink] = useState(null);
    const [pagingLabel, setPagingLabel] = useState(null);

    const locale = getLocaleFromPath(location.pathname);
    const tag = location.pathname.split('/').slice(3).join('/');

    var urlParams = new URLSearchParams(location.search);

    const page = urlParams.has('page') ? parseInt(urlParams.get('page')) : 1;

    const limit = 12;
    const offset = (page-1)*limit;

    var videosUrl = query.site.siteMetadata.videoRest.endpoints.taggedVideos+'?limit='+limit+'&offset='+offset+'&tags__slug='+tag.replace(/\/+$/, '')

    useEffect(() => {
        fetch(videosUrl)
            .then(response => response.json())
            .then(result => {
                const totalPages = Math.ceil(result.count / limit)
                var pg = page;
                if (totalPages > 0) {
                    if (pg<1)
                        pg = 1;
                    if (pg>totalPages)
                        pg = totalPages;
                } else {
                    pg = null;
                }

                result.previous && setPrevLink('?page='+(pg-1));
                result.next && setNextLink('?page='+(pg+1));
                if (totalPages>1)
                    setPagingLabel(pg+ '/' + totalPages);

                return result.results;
            })
            .then(result => {
                setVideos(result);
                setTitle(tag===null ? 'Videá' : tag);
            })
            .catch(error => {
                console.log(error);
                setVideos([]);
                setTitle('Videá');
            })
    }, [videosUrl, tag, page, limit]);

    return (!videos ? '' : (
        <Layout location={location}>
            <div className="MainContent">
                <Videos title={title} locale={locale} items={videos}/>
                <Paging prevLink={prevLink} nextLink={nextLink} label={pagingLabel}/>
            </div>
        </Layout>
    ));
};

export default withI18n()(IndexPage);

